import styled from '@emotion/styled';
import React from 'react';
import { navigate } from 'gatsby-link';

import SEO from '../components/seo';
import PublicLayout from '../layout/public-layout';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import { SYSTEM_UPGRADE_PASSWORD_RESET, CONTACT_US_URL } from '../constants';

const Container = styled.div`
  margin: 0 auto;
  padding: 30px;
`;

export default function PasswordResetSubmitConfirmation() {
  return (
    <div>
      <SEO title="Password Reset Required" />;
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            headingText={SYSTEM_UPGRADE_PASSWORD_RESET.DISPLAY_MESSAGE}
            paragraphText={SYSTEM_UPGRADE_PASSWORD_RESET.INSTRUCTION_TEXT}
            buttonText={SYSTEM_UPGRADE_PASSWORD_RESET.BUTTON_TEXT}
            onButtonClick={() => navigate(CONTACT_US_URL)}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
